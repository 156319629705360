@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(49, 49, 49);
    height: 100dvh;
    overflow-y: hidden;
}
@layer base {
    :root {
        --bg-sys-pri: #e8e8e8;
        --bg-sys-sec: #cecece;

        --text-pri: #000000;
        --text-sec: #202020;

        --br-pri: #0b0b0b;
        --br-sec: #212121;
    }
    body.dark {
        --bg-sys-pri: #141414;
        --bg-sys-sec: #1e1e1e;

        --text-pri: #f1f1f1;
        --text-sec: #c6c6c6;

        --br-pri: #f2f2f2;
        --br-sec: #d0d0d0;
        --br-sec2: #2f2f2f;
    }
}

.spinner {
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.navBar {
    box-shadow: black 0px 0px 20px 1px;
}
a {
    text-decoration-line: none;
}

.cBorder {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    /* animation: gradients 15s ease infinite; */
}
@keyframes gradients {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gradient-text {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    /* Define your gradient colors here */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Fallback for browsers that don't support gradient text */
    background-clip: text;
    color: transparent;
}

.cBorder2 {
    background: linear-gradient(-45deg, #e73c7e, #23a6d5);
    /* animation: gradients 15s ease infinite; */
}

/* .MuiMenu-list{
    background-color:  #212529 !important;
} */

/* .css-12u49z-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: solid #0080ff 2px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgb(255 255 255 / 40%) !important;
} */
/* .css-12u49z-MuiDataGrid-root .MuiDataGrid-row:hover,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
    background-color: #ffffff08 !important;
} */

/* .MuiOutlinedInput-notchedOutline {
    border-color: rgb(255 255 255 / 35%) !important;
} */
/* .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    background-color: #212529 !important;
    color: rgba(37, 37, 37, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 16px;
    min-height: 16px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;
} */

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(77, 77, 77);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3d3d3d;
}

::-webkit-scrollbar-corner {
    background: #27272a;
}

.glass {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 999999999px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    /* box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); */
}
.glassCon {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 999999999px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.37);
}

#divToPrint {
    padding-right: 0.7cm !important;
}
@media print {
    @page {
        size: portrait;
        margin: 0.4in 0.1in;
    }

    body > :first-child {
        border-radius: 0.5rem;
        overflow: hidden;
    }
    #footer {
        page-break-before: auto;
        page-break-inside: avoid;
    }
}
/* @media print {

    .no-break,
    #reportCon,
    #header,
    #tableContainer,
}
.no-break,
#reportCon,
#header,
#tableContainer,
#footer {
    page-break-before: auto !important;
    page-break-after: auto !important;
    page-break-inside: auto !important;
} */
/* @media print {
    body * {
        visibility: hidden;
    }

    #app1 {
        display: none;
    }

    #divToPrint,
    #divToPrint * {
        visibility: visible !important;
    }

    #divToPrint {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        page-break-before: always;
        background-color: white;
    }
    

} */
